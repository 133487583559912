import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import API_URL from "../../../config/constant";

export default function Podium() {
    const { t } = useTranslation();
    const [data, setData] = useState(null);
    const locale = useSelector((state) => state.locale.locale);

    useEffect(() => {
        const fetchAboutData = async () => {
            try {
                const response = await fetch(`${API_URL}/home?lang=${locale}`);
                if (!response.ok) {
                    throw new Error("Data not found");
                }
                const res = await response.json();
                if (res.podium.data.length < 1) {
                    throw new Error("Data not found");
                }
                setData(res.podium);
            } catch (error) {
                setData(t("home.podium", { returnObjects: true }));
            }
        };

        fetchAboutData();
    }, [t, locale]);
    return (
        <>
            <div className="w-full bg-categoryPodium relative bg-center bg-cover bg-no-repeat min-h-[50rem]">
                <div className="absolute bg-graphicPrize w-full h-full bg-bottom bg-cover opacity-15"></div>
                <div className="container mx-auto text-center font-beVietnam text-white py-24 space-y-10 relative">
                    <h1 className="uppercase italic font-bold 2xl:text-5xl text-3xl">
                        {data?.title}
                    </h1>
                    <div className="w-full flex items-center justify-center">
                        <div className="grid md:grid-cols-3 gap-10 w-4/6">
                            {data?.data.map((value, index) => (
                                <div className=" rounded-lg" key={index}>
                                    <div className="2xl:py-14 md:py-10 py-7 bg-black/30 backdrop-blur-xl text-center rounded-t-lg">
                                        <h1 className="font-bold 2xl:text-2xl text-xl">
                                            {value.title}
                                        </h1>
                                    </div>
                                    <div className="bg-neutral-950 2xl:py-10 md:py-5 px-10 rounded-b-lg font-light h-60">
                                        <p dangerouslySetInnerHTML={{ __html: value.desc }} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
