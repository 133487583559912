/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import LeMinerale from "../../../assets/images/sponsor/leminerale.png";
import LogoPemkot from "../../../assets/images/sponsor/pemkot-semarang.png";
import Kompas from "../../../assets/images/sponsor/kompas.png";
import Isoplus from "../../../assets/images/sponsor/isoplus.png";
import Asics from "../../../assets/images/sponsor/asics.png";
import Posh from "../../../assets/images/sponsor/posh.png";
import Generali from "../../../assets/images/sponsor/generali.png";
import EjSport from "../../../assets/images/sponsor/ejsport.png";
import Oatside from "../../../assets/images/sponsor/oatside.png";
import Etawalin from "../../../assets/images/sponsor/etawalin.png";
import Fitbar from "../../../assets/images/sponsor/fitbar.png";
import Avant from "../../../assets/images/sponsor/avant.png";
import Panasonic from "../../../assets/images/sponsor/panasonic.png";
import Salonpas from "../../../assets/images/sponsor/salonpas.png";
import Rooms from "../../../assets/images/sponsor/roomsinc.png";
import Santika from "../../../assets/images/sponsor/santika.png";
import Mills from "../../../assets/images/sponsor/mills.png";
import Kemenkes from "../../../assets/images/sponsor/rskariadi.png";
import ThePark from "../../../assets/images/sponsor/theparksemarang.png";

import IA from "../../../assets/images/sponsor/ia-colour.png";
import Tasangin from "../../../assets/images/sponsor/tasangin.png";
import UR from "../../../assets/images/sponsor/urban-republic.png";
import Sunpride from "../../../assets/images/sponsor/sunpride.png";

export default function Sponsor() {
    return (
        <div className="bg-white font-beVietnam">
            <div className="relative container w-full mx-auto py-24">
                <div className="grid md:grid-cols-3 uppercase gap-10 py-10">
                    <div className="space-y-4 flex flex-col justify-center items-center">
                        <h2 className="text-xl font-bold">Main Sponsor</h2>
                        <a href="https://www.leminerale.com/" target="_blank" rel="noreferrer">
                            <img src={LeMinerale} alt="" className="md:w-1/2 w-2/3 mx-auto" />
                        </a>
                    </div>
                    <div className="space-y-4 flex flex-col justify-start items-center">
                        <h2 className="text-xl font-bold">Hosted By</h2>
                        <img src={LogoPemkot} alt="" className="md:w-1/2 w-2/3" />
                    </div>
                    <div className="space-y-4 flex flex-col justify-start items-center">
                        <h2 className="text-xl font-bold">Organized By</h2>
                        <div className="flex h-full items-center justify-center">
                            <img src={Kompas} alt="" className="md:w-1/2 w-2/3" />
                        </div>
                    </div>
                </div>

                <div className="grid md:grid-cols-2 uppercase gap-10 py-10 px-10 text-center">
                    <div className="space-y-4 flex flex-col justify-start items-center">
                        <h2 className="text-xl font-bold">OFFICIAL ISOTONIC PARTNER</h2>
                        <div className="flex h-full items-center">
                            <a
                                href="https://wingscorp.com/brand-detail/isoplus/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={Isoplus} alt="" className="lg:w-1/3 w-1/2 mx-auto" />
                            </a>
                        </div>
                    </div>
                    <div className="space-y-4 flex flex-col justify-start items-center text-center">
                        <h2 className="text-xl font-bold">OFFICIAL ANTIPERSPIRANT PARTNER</h2>
                        <div className="flex h-full items-center">
                            <a
                                href="https://www.poshindonesia.id/girl/deodorant-girl/anti-stain"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={Posh} alt="" className="lg:w-1/3 w-1/2 mx-auto" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="py-14">
                    <div className="text-center mb-10">
                        <h2 className="text-xl font-bold">CO SPONSOR</h2>
                    </div>

                    <div className="grid md:grid-cols-6 place-content-center items-center md:gap-x-24 gap-x- px-8">
                        <div className="">
                            <a href="https://injourney.id/" target="_blank" rel="noreferrer">
                                <img src={IA} alt="" className="lg:w-fit w-1/2 mx-auto" />
                            </a>
                        </div>
                        <div className="">
                            <a href="https://www.generali.co.id/" target="_blank" rel="noreferrer">
                                <img src={Generali} alt="" className="lg:w-fit w-1/2 mx-auto" />
                            </a>
                        </div>
                        <div className="">
                            <a href="https://asics.co.id/" target="_blank" rel="noreferrer">
                                <img src={Asics} alt="" className="lg:w-fit w-1/2 mx-auto" />
                            </a>
                        </div>
                        <div className="">
                            <a href="https://ejsportofficial.com/" target="_blank" rel="noreferrer">
                                <img src={EjSport} alt="" className="lg:w-fit w-1/2 mx-auto" />
                            </a>
                        </div>
                        <div className="">
                            <a href="https://oatside.com/" target="_blank" rel="noreferrer">
                                <img src={Oatside} alt="" className="lg:w-fit w-1/2 mx-auto" />
                            </a>
                        </div>
                        <div className="">
                            <a href="https://etawalinku.com/" target="_blank" rel="noreferrer">
                                <img src={Etawalin} alt="" className="lg:w-fit w-1/2 mx-auto" />
                            </a>
                        </div>
                    </div>
                    <div className="grid md:grid-cols-5 place-content-center items-center md:gap-x-24 gap-x- px-8">
                        <div className="">
                            <a href="https://baja88.co.id/" target="_blank" rel="noreferrer">
                                <img src={Avant} alt="" className="lg:w-4/5 w-2/3 mx-auto" />
                            </a>
                        </div>

                        <div className="">
                            <a
                                href="https://kalbenutritionals.com/id/produk-kami/fitbar"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={Fitbar} alt="" className="lg:w-4/5 w-2/3 mx-auto" />
                            </a>
                        </div>
                        <div className="">
                            <a href="#">
                                <img src={Tasangin} alt="" className="lg:w-4/5 w-2/3 mx-auto" />
                            </a>
                        </div>
                        <div className="">
                            <a
                                href="https://www.panasonic.com/id/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={Panasonic} alt="" className="lg:w-4/5 w-2/3 mx-auto" />
                            </a>
                        </div>
                        <div className="">
                            <a
                                href="https://id.hisamitsu/home/about"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={Salonpas} alt="" className="lg:w-4/5 w-2/3 mx-auto" />
                            </a>
                        </div>
                    </div>
                    <div className="grid md:grid-cols-5 place-content-center items-center md:gap-x-24 gap-x- px-8">
                        <div className="">
                            <a href="" target="_blank" rel="noreferrer">
                                <img src={UR} alt="" className="lg:w-4/5 w-2/3 mx-auto" />
                            </a>
                        </div>
                        <div>
                            <a
                                href="https://www.instagram.com/roomsinc.semarang/?hl=en"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={Rooms} alt="" className="lg:w-4/5 w-2/3 mx-auto" />
                            </a>
                        </div>
                        <div>
                            <a
                                href="https://www.instagram.com/santikapremieresemarang/?hl=en"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={Santika} alt="" className="lg:w-4/5 w-2/3 mx-auto" />
                            </a>
                        </div>
                        <div>
                            <a href="https://mills.co.id/" target="_blank" rel="noreferrer">
                                <img src={Mills} alt="" className="lg:w-4/5 w-2/3 mx-auto" />
                            </a>
                        </div>
                        <div>
                            <a href="" target="_blank" rel="noreferrer">
                                <img src={Sunpride} alt="" className="lg:w-4/5 w-2/3 mx-auto" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="grid md:grid-cols-2 grid-cols-1 gap-20 max-w-4xl mx-auto px-auto px-8">
                    <div className="text-center space-y-4">
                        <h2 className="text-xl font-bold uppercase">Medical Partner</h2>
                        <div className=" flex items-center justify-center">
                            <a href="https://www.rskariadi.co.id/" target="_blank" rel="noreferrer">
                                <img src={Kemenkes} alt="" className="md:w-1/2 w-1/2 mx-auto" />
                            </a>
                        </div>
                    </div>
                    <div className="text-center space-y-4">
                        <h2 className="text-xl font-bold uppercase">Venue Partner</h2>
                        <div className=" flex items-center justify-center">
                            <a
                                href="https://www.instagram.com/thepark.semarang/?hl=en"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={ThePark} alt="" className="md:w-1/2 w-1/2 mx-auto" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
