/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import galleryConfigs from "../../constant/gallery";
import fetchGallery from "../../helper/getGallery";
import GalleryLoading from "../../components/shared/GalleryLoading";
import { XMarkIcon } from "@heroicons/react/24/solid";

export default function Index() {
    const config2022 = galleryConfigs.galleryConfig2022;
    const config2023 = galleryConfigs.galleryConfig2023;
    const config2024 = galleryConfigs.galleryConfig2024;

    const [galleryData, setGalleryData] = useState([]);

    const [isLoading, setLoading] = useState(false);
    const [activeYear, setActiveYear] = useState(2022);
    const [configSelected, setConfigSelected] = useState(config2022);
    const [locationOption, setLocationOption] = useState(configSelected.locations);
    const [activeLocation, setActiveLocation] = useState({
        year: activeYear,
        value: "Start",
    });
    const [imageUrl, setImageUrl] = useState(null);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [pageNumber, setPageNumber] = useState(1);
    const [inputValue, setInputValue] = useState(""); // Menyimpan value langsung dari input
    const [debouncedValue, setDebouncedValue] = useState(""); // Menyimpan value dengan delay
    const [isComingSoon, setIsComingSoon] = useState(false);

    const btnClass =
        "bg-neutral-900 border border-neutral-700 px-6 py-3 rounded-lg hover:bg-neutral-700 transition-all duration-500";

    const handleChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleScroll = () => {
        if (document.body.scrollHeight - 300 < window.scrollY + window.innerHeight) {
            setLoading(true);
        }
    };

    function debounce(func, delay) {
        let timeoutId;
        return function(...args) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    window.addEventListener("scroll", debounce(handleScroll, 500));

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            setDebouncedValue(inputValue);
        }, 4000);
        fetchData();
        return () => clearTimeout(delayDebounce);
    }, [inputValue]);

    const switchYear = async (year) => {
        setPageNumber(1);
        setGalleryData([]);
        setActiveYear(year);
        if (year === 2022) {
            setIsComingSoon(false);
            setConfigSelected(config2022);
            setLocationOption(config2022.locations);
        } else if (year === 2023) {
            setIsComingSoon(false);
            setConfigSelected(config2023);
            setLocationOption(config2023.locations);
        } else if (year === 2024) {
            setIsComingSoon(false);
            setConfigSelected(config2024);
            setLocationOption(config2024.locations);
        }
        setActiveLocation({
            year: year,
            value: "Start",
        });
    };

    const switchLocation = async (year, value) => {
        setPageNumber(1);
        setGalleryData([]);
        setActiveLocation({
            year: year,
            value: value,
        });
    };

    useEffect(() => {
        if (isLoading === true && galleryData.length > 0) {
            setPageNumber((prevPage) => prevPage + 1);
        }
    }, [isLoading]);

    // other useEffect that we already implemented

    useEffect(() => {
        fetchData();
    }, [pageNumber]);

    useEffect(() => {
        fetchData();
    }, [activeYear, activeLocation, configSelected]);

    const fetchData = async () => {
        setLoading(true);
        console.log(configSelected.eventName);
        const response = await fetchGallery(
            configSelected.eventName,
            activeLocation?.value,
            pageNumber,
            inputValue
        );

        if (response === null) {
            setGalleryData([]);
        } else {
            setGalleryData((prevData) => [...prevData, ...response]);
        }
        setLoading(false);
    };

    const openLightBox = (img) => {
        setLightboxOpen(!lightboxOpen);
        setImageUrl(img);
        document.body.style.overflow = "hidden";
    };

    const closeLightBox = () => {
        setLightboxOpen(!lightboxOpen);
        setImageUrl(null);
        document.body.style.overflow = "unset";
    };

    return (
        <div className="bg-black relative ">
            <div className="flex mx-auto items-start justify-start z-10 min-h-screen 2xl:pt-56 md:pt-40 pt-24 w-full bg-black bg-pageTitle bg-no-repeat">
                <div className="2xl:px-0 md:px-14 px-0 w-full py-4">
                    <div className="w-full font-beVietnam text-white">
                        <div className="container h-full mx-auto w-full">
                            <div className="w-full mb-8">
                                <div className="h-full space-y-4">
                                    <div className="flex md:flex-row flex-col items-center justify-between md:gap-y-0 gap-y-6">
                                        <h1 className="2xl:text-5xl text-3xl uppercase font-bold max-w-2xl italic">
                                            Race Gallery
                                        </h1>
                                        <div className="space-x-4">
                                            <button
                                                onClick={() => switchYear(2022)}
                                                className={`${btnClass} ${
                                                    activeYear === 2022 ? "bg-red-700" : null
                                                }`}
                                            >
                                                2022
                                            </button>
                                            <button
                                                onClick={() => switchYear(2023)}
                                                className={`${btnClass} ${
                                                    activeYear === 2023 ? "bg-red-700" : null
                                                }`}
                                            >
                                                2023
                                            </button>
                                            <button
                                                onClick={() => switchYear(2024)}
                                                className={`${btnClass} ${
                                                    activeYear === 2024 ? "bg-red-700" : null
                                                }`}
                                            >
                                                2024
                                            </button>
                                        </div>
                                    </div>
                                    <div className="md:px-0 px-6">
                                        <input
                                            id="name"
                                            name="name"
                                            type="text"
                                            className="bg-neutral-950 block border border-neutral-600 w-full 2xl:py-6 2xl:px-6 p-4 rounded-lg placeholder:text-neutral-600"
                                            placeholder="Search your BNIB here"
                                            value={inputValue}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="flex flex-wrap justify-center gap-4 md:px-0 px-2">
                                        {!isComingSoon &&
                                            locationOption.map((value, index) => (
                                                <button
                                                    onClick={() =>
                                                        switchLocation(activeLocation, value)
                                                    }
                                                    className={`${btnClass} ${
                                                        activeLocation?.value === value
                                                            ? "bg-red-700"
                                                            : null
                                                    }`}
                                                >
                                                    {value}
                                                </button>
                                            ))}
                                    </div>
                                    {/* <div className="grid grid-cols-2 md:grid-cols-4 gap-4"> */}
                                    <div className="p-5 sm:p-8">
                                        {isComingSoon ? (
                                            <h1 className="text-center font-bold italic">
                                                Coming Soon
                                            </h1>
                                        ) : (
                                            <div className="columns-1 gap-5 sm:columns-2 sm:gap-8 md:columns-3 lg:columns-4 [&>div:not(:first-child)]:mt-8">
                                                {!isLoading ? (
                                                    galleryData ? (
                                                        galleryData.map((value) => (
                                                            <div className="h-auto max-w-full rounded-lg ">
                                                                <img
                                                                    onClick={() =>
                                                                        openLightBox(value.mftgUrl)
                                                                    }
                                                                    loading="lazy"
                                                                    key={value.id}
                                                                    src={value.mftgUrl}
                                                                    alt={value.mftgDescription}
                                                                    className="rounded-lg cursor-zoom-in"
                                                                />
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <h1 className="text-white">
                                                            Data Not Found
                                                        </h1>
                                                    )
                                                ) : (
                                                    <GalleryLoading />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div
                                id="lightbox"
                                className={`${
                                    lightboxOpen ? "fixed" : "hidden"
                                } inset-0 z-50 bg-black/75 overflow-auto flex items-center justify-center`}
                            >
                                <div className="md:w-1/2 p-4">
                                    <div className="flex justify-end">
                                        <button
                                            onClick={() => closeLightBox()}
                                            className="bg-white text-black p-2.5 rounded-full mb-3"
                                        >
                                            <XMarkIcon width={24} />
                                        </button>
                                    </div>
                                    <img src={imageUrl} alt="Gambar Kucing" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
