import React, { useEffect, useState } from "react";
import ImageAbout from "../../../assets/images/about.png";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import API_URL from "../../../config/constant";

export default function About() {
    const { t } = useTranslation();
    const [aboutData, setAboutData] = useState(null);
    const locale = useSelector((state) => state.locale.locale);
    useEffect(() => {
        const fetchAboutData = async () => {
            try {
                // eslint-disable-next-line no-undef
                const response = await fetch(`${API_URL}/home?lang=${locale}`);
                if (!response.ok) {
                    throw new Error("Data not found");
                }
                const data = await response.json();
                if (data.about === null) {
                    throw new Error("Data not found");
                }
                setAboutData(data.about);
            } catch (error) {
                setAboutData(t("home.about", { returnObjects: true }));
            }
        };

        fetchAboutData();
    }, [t, locale]);
    return (
        <div className="bg-black relative font-beVietnam">
            <div className="grid md:grid-cols-2 min-h-full h-full text-white">
                <div className="place-content-center w-full md:pl-24 md:pr-10 px-10 py-10 uppercase space-y-2">
                    <h1>{aboutData?.title}</h1>
                    <h2 className="2xl:text-8xl md:text-6xl text-4xl font-bold italic">
                        SEMARANG 10K
                    </h2>
                    <div className="space-y-5 normal-case font-beVietnam font-light md:w-4/5 text-justify">
                        <div
                            className="space-y-5"
                            dangerouslySetInnerHTML={{ __html: aboutData?.content }}
                        ></div>
                    </div>
                </div>
                <div className="md:place-content-end md:items-end md:justify-end md:flex">
                    <img src={ImageAbout} alt="" className="w-full" />
                </div>
            </div>
        </div>
    );
}
