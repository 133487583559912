import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import API_URL from "../../config/constant";
export default function CategoryPodium() {
    const { t } = useTranslation();

    const [data, setData] = useState(null);
    const locale = useSelector((state) => state.locale.locale);
    useEffect(() => {
        const fetchAboutData = async () => {
            try {
                const response = await fetch(`${API_URL}/faq?lang=${locale}`);
                if (!response.ok) {
                    throw new Error("Data not found");
                }
                const res = await response.json();
                if (res.podium.data.length < 1) {
                    throw new Error("Data not found");
                }
                setData(res.podium);
            } catch (error) {
                setData(t("home.podium", { returnObjects: true }));
            }
        };

        fetchAboutData();
    }, [t, locale]);
    return (
        <div className="w-full min-h-screen h-full relative 2xl:pb-0 md:pb-0 pb-96">
            <div className="w-full bg-categoryPodium min-h-screen h-full bg-no-repeat bg-cover bg-center text-white md:pb-16">
                <div className="container mx-auto w-full relative 2xl:top-56 md:top-34 top-48 2xl:px-0 md:px-4">
                    <div className="2xl:px-0 md:px-24 px-0">
                        <h1 className="2xl:text-4xl text-2xl font-beVietnam font-bold italic uppercase text-white text-center">
                            {data?.title}
                        </h1>
                    </div>
                    <div className="flex w-full items-center justify-center mt-10 text-center font-beVietnam">
                        <div className="grid md:grid-cols-3 gap-10 w-4/6">
                            {data?.data.map((value, index) => (
                                <div className="border border-neutral-800 rounded-lg" key={index}>
                                    <div className="2xl:py-14 py-7 bg-black/30 backdrop-blur-xl text-center rounded-t-lg">
                                        <h1 className="font-bold md:text-2xl text-xl">
                                            {value.title}
                                        </h1>
                                    </div>
                                    <div className="bg-neutral-950 py-5 px-5 rounded-b-lg font-light h-52 2xl:text-base text-sm">
                                        <p dangerouslySetInnerHTML={{ __html: value.desc }} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
