import React, { useEffect, useState } from "react";
import PageTitle from "../../components/shared/PageTitle";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "../../rules-regulations.css";
import API_URL from "../../config/constant";
export default function RulesRegulation() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { t } = useTranslation();

    const [data, setData] = useState(null);
    const locale = useSelector((state) => state.locale.locale);
    useEffect(() => {
        const fetchAboutData = async () => {
            try {
                const response = await fetch(`${API_URL}/rules-regulations?lang=${locale}`);
                if (!response.ok) {
                    throw new Error("Data not found");
                }
                const res = await response.json();
                if (res.length < 1) {
                    throw new Error("Data not found");
                }
                setData(res);
                console.log(res);
            } catch (error) {
                setData(t("raceinfo.rr", { returnObjects: true }));
            }
        };

        fetchAboutData();
    }, [t, locale]);

    return (
        <div className="text-white md:px-0 px-4 bg-black 2xl:pb-96 md:pb-96 pb-80">
            <div className="relative">
                <PageTitle title={"Rules & Regulations"} />
            </div>
            <div className="relative top-80 bg-black 2xl:px-0 md:px-14 px-0">
                <div className="bg-black min-h-screen container mx-auto w-full font-beVietnam text-white">
                    <div className="flex items-start justify-start flex-col gap-y-10">
                        <p className="text-start md:w-1/2">{t("raceinfo.rrTitle")}</p>
                    </div>
                    <div className="flex md:flex-row flex-col gap-x-24 mt-10">
                        <div className="flex flex-col md:gap-y-10 gap-y-5 mb-10">
                            {data?.map((parent, index) => (
                                <button
                                    onClick={() => setCurrentIndex(index)}
                                    className={`uppercase text-start w-52 text-lg ${
                                        currentIndex === index
                                            ? "text-white border-b border-b-white font-bold"
                                            : "text-neutral-500 font-normal border-none"
                                    }`}
                                >
                                    {parent.title}
                                </button>
                            ))}
                        </div>
                        <div>
                            <ul className="font-light list-decimal  leading-loose">
                                {data && data[currentIndex].data && (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: data[currentIndex].data,
                                        }}
                                    />
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
