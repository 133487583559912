import React, { useEffect, useState } from "react";
import PageTitle from "../../components/shared/PageTitle";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import API_URL from "../../config/constant";
export default function Faq() {
    const [currentIndex, setCurrentIndex] = useState(null);
    const { t } = useTranslation();

    const [data, setData] = useState(null);
    const locale = useSelector((state) => state.locale.locale);
    useEffect(() => {
        const fetchAboutData = async () => {
            try {
                const response = await fetch(`${API_URL}/faq?lang=${locale}`);
                if (!response.ok) {
                    throw new Error("Data not found");
                }
                const res = await response.json();
                if (res.length < 1) {
                    throw new Error("Data not found");
                }
                setData(res);
            } catch (error) {
                setData(t("faq", { returnObjects: true }));
            }
        };

        fetchAboutData();
    }, [t, locale]);
    return (
        <div className="text-white md:px-0 px-4 relative z-30">
            <div className="relative">
                <PageTitle title={"Frequently Asked Question"} />
            </div>
            <div className="top-80 relative bg-black 2xl:px-0 md:px-14 px-0 2xl:pb-24 pb-96">
                <div className="bg-black min-h-screen container mx-auto w-full font-beVietnam text-white">
                    {data?.map((value, index) => (
                        <div
                            key={index}
                            className="py-10 border-b border-neutral-600 w-full"
                            onClick={() =>
                                index === currentIndex
                                    ? setCurrentIndex(null)
                                    : setCurrentIndex(index)
                            }
                        >
                            <div className="w-full flex items-center justify-between">
                                <h1 className="md:text-xl text-lg uppercase">{value.title}</h1>
                                <button>
                                    {index === currentIndex ? (
                                        <MinusIcon width={24} className="text-red-500" />
                                    ) : (
                                        <PlusIcon width={24} className="text-white" />
                                    )}
                                </button>
                            </div>
                            <div
                                className={`mt-4 w-full ${
                                    index === currentIndex ? "block" : "hidden"
                                }`}
                            >
                                <ul className="space-y-4 w-full">
                                    {value.data.map((data, i) => (
                                        <li className="space-y-2 w-full">
                                            <h2 className="font-medium text-md">
                                                {i + 1}. {data.question}
                                            </h2>
                                            <div
                                                dangerouslySetInnerHTML={{ __html: data?.answer }}
                                                className="font-light text-neutral-200"
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
