import React, { useState } from "react";
import PageTitle from "../../components/shared/PageTitle";

export default function RaceResult() {
    const [currentIndex, setCurrentIndex] = useState(0);
    return (
        <div className="text-white md:px-0 px-4">
            <div className="relative">
                <PageTitle title={"Race Result"} />
            </div>
            <div className="top-80 relative bg-black pb-96">
                <div className="bg-black min-h-screen container mx-auto w-full font-beVietnam text-white ">
                    <div className="flex items-center justify-center flex-row gap-x-10">
                        <button
                            onClick={() => setCurrentIndex(0)}
                            className={`py-2 border-white px-8 text-lg ${
                                currentIndex === 0 ? "font-bold border-b" : ""
                            }`}
                        >
                            2023
                        </button>
                        <button
                            onClick={() => setCurrentIndex(1)}
                            className={`py-2 border-white px-8 text-lg ${
                                currentIndex === 1 ? "font-bold border-b" : ""
                            }`}
                        >
                            2024
                        </button>
                    </div>
                    <div className="mt-20 2xl:px-0 md:px-14 px-0">
                        {currentIndex === 0 ? (
                            <iframe
                                title="Lokasi"
                                src="https://results.scorenow.co.id/results.aspx?CId=19723&RId=156"
                                allowfullscreen="true"
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                                className="w-full h-[90rem] rounded-lg"
                            ></iframe>
                        ) : (
                            <iframe
                                title="Lokasi"
                                src="https://result.race.id/results.aspx?CId=20127&RId=6005"
                                allowfullscreen="true"
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                                className="w-full h-[90rem] rounded-lg"
                            ></iframe>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
